import { render, staticRenderFns } from "./WgTrade.vue?vue&type=template&id=377b9293&scoped=true"
import script from "./WgTrade.vue?vue&type=script&lang=js"
export * from "./WgTrade.vue?vue&type=script&lang=js"
import style0 from "./WgTrade.vue?vue&type=style&index=0&id=377b9293&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "377b9293",
  null
  
)

export default component.exports