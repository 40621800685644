<template>
    <div class="swiper-box">
        <div class="swiper-content w1364">
            <div class="scientific_title">
            <p>{{ title }}
            </p>
            <span class="seeMore" @click="seeMore">查看更多</span>
        </div>
          <div class="swiper">
              <swiper :options="swiperOptions" v-if="slides.length">
                  <swiper-slide v-for="(slide, index) in slides" :key="index">
                      <img  :src="slide.thumbnailInfo.thumbnailUrl" alt="" @click="toDetail(slide)">
                      <p>{{ slide.title }}</p>
                  </swiper-slide>
              </swiper>
              <!-- 翻页按钮 -->
              <div class="swiper-button-prev" ></div>
              <div class="swiper-button-next" ></div>
          </div>
        </div>
    </div>
	

</template>
<script>
 export default {
    name:"WgSwiper",
    props: {
    columnIds: {
      type: String,
      default: () => {
        return "";
      },
    },
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
    data() {
        return {
            slides:[],
            swiperOptions: {
                effect: 'coverflow',
                loop:true,
                autoplay: {
                  delay: 3000, // 自动播放间隔时间，单位是毫秒
                  disableOnInteraction: false, // 用户操作后是否停止自动播放
                },
                grabCursor: true,
                centeredSlides: true,
                initialSlide: 1, //初始索引
                slidesPerView: 'auto',
                coverflowEffect: {
                    rotate: 0,
                    stretch: 300,
                    depth: 500,
                    modifier: 1,
                    slideShadows: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
             
            },
            active: 0,

        }
    },

    methods: {
        toDetail(item) {
        this.$emit("toDetail", item);
        },
        seeMore() {
        this.$emit("seeMore", { columnId: this.columnIds, title: this.title });
        },
    },
    watch: {
    columnIds: {
      async handler(val) {
        if (val) {
          this.active = 0;
          const params = {
            pageNumber: 0,
            pageSize: 5,
            columnIds: val,
          };
          const res = await this.API.basic.getHomeRotographList(params);
          if(res.data.totalElements.length != 0){
            this.slides = res.data.content.filter(item=>{
            if(item.thumbnailInfo.thumbnailUrl != ''){
                return item;
            }
          });
          }else{
            this.slides = []
          }
         
        }
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.swiper-box{
  
  background: url("../assets/img/ddh2017_09.jpg") no-repeat;
  background-size: 100% 100%;
}
.swiper-content{
 
}
.swiper {
  
 width: 1380px;
 height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.swiper-container {
  width: 1070px;
  height: 350px;
}
.swiper-slide {
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.swiper-slide p{
    position: absolute;
    width: 100%;
    color: #fff;
    box-sizing: border-box;
    padding: 10px;
    bottom: 0;
    background-color: #0000008e;
}
.swiper-slide img{
    width: 100%;
    height: 100%;
}
.scientific_title {
  height: 80px;
  line-height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.scientific_title p {
  width: 400px;
  font-weight: 500;
  font-size: 28px;
  // color: #000000;
  /* margin-left: 100px; */
  color: #fff;
}
.scientific_title p span {
  font-size: 24px;
  color: #999;
  margin-left: 10px;
  font-weight: 400;
}
.seeMore {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  opacity: 0.4;
  cursor: pointer;
}
.swiper-button-prev{
    background-image: url("../assets/img/arrow-left.png");
    left: 80px;
}
.swiper-button-next{
    background-image: url("../assets/img/arrow-right.png");
    right: 80px;
}
</style>