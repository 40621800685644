<template>
  <div class="noTitle_contextBg">
  <div class="noTitle_context w1364">
    <div class="news_info_title">
      <p>{{ title }}
<!--        <span>NEWS</span>-->
      </p>
      <span class="seeMore" @click="seeMore">查看更多</span>
    </div>
    <dl v-for="(item, index) in datas" :key="index" style="cursor: pointer;margin-bottom: 1.04167vw;"  @click="toDetail(item)" class="item">
      <dt>
<!--        <p>{{ $moment(item.publishTime).format("DD") }}</p>-->
        <p style="font-size: 0.83333vw;">{{ $moment(item.publishTime).format("YYYY-MM-DD") }}</p>
      </dt>
      <dd>
        <h4 class="title">
          {{ getTwoLine(item.title) }}
        </h4>
<!--        <p class="msg">{{ item.description }}</p>-->
      </dd>
    </dl>
  </div>
</div>
</template>
<script>
export default {
  name: "WgList",
  props: {
    columnIds: {
      type: String,
      default: () => {
        return "";
      },
    },
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
    maxLength: {
      type: Number,
      default: () => {
        return 4;
      },
    },
  },
  data() {
    return {
      datas: [],
    };
  },
  methods: {
    toDetail(item) {
      this.$emit("toDetail", item);
    },
    seeMore() {
      this.$emit("seeMore", { columnId: this.columnIds, title: this.title });
    },
    getTwoLine(str) {
      if (str.length >= 39 ) {
        return str.slice(0, 39) + '...';
      } else {
        return str;
      }
    }
  },
  watch: {
    columnIds: {
      async handler(val) {
        if (val) {
          const params = {
            pageNumber: 0,
            pageSize: 10,
            columnIds: val,
          };
          const res = await this.API.basic.getHomeRotographList(params);
          if(res.data.totalElements>0){
            this.datas = res.data.content.slice(0, this.maxLength);

          }else{
            this.datas = []
          }
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.news_info_title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.news_info_title p {
  font-weight: 500;
  font-size: 28px;
  color: #000000;
  /* margin-left: 100px; */
}
.news_info_title p span {
  margin-left: 12px;
  //font-family: PingFang SC;
  font-weight: 400;
  font-size: 24px;
  color: #000000;
  opacity: 0.4;
}
.seeMore {
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  opacity: 0.4;
}
.noTitle_contextBg{
  background: url("../assets/img/bg3.png") no-repeat;
  background-size: 100% 100%;
  .w1364{
    padding: 30px 0px!important;
  }
}
.noTitle_context {
  padding: 30px 268px 30px 271px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.noTitle_context dl {
  width: 49%;
  padding-bottom: 10px;
  border-bottom: 2px solid white;
  float: left;
  display: flex;
  padding-top: 10px;
}
.noTitle_context dl dt {
  width: 160px;
  min-width: 44px;
  height: 50px;
  border: 2px solid rgb(161, 41, 22);;
  border-radius: 10px;
  margin-right: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 12px;
  margin-top: 16px;
}
// .noTitle_context dl:first-child dt {
//   border: 1px solid red;
//   background: #a12916;
//   color: white;
// }
.noTitle_context dt p {
  text-align: center;
  //font-family: PingFang SC;
  font-weight: 400;
  font-size: 20px;
  color: rgb(161, 41, 22);;
}
.noTitle_context dt p:nth-child(2) {
 // font-family: PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
}
.noTitle_context dd {
  font-size: 18px;
  width: 650px;
  display: flex;
  align-items: center;
}
.noTitle_context dd p {
  margin-top: 24px;
  line-height: 24px;
  font-size: 14px;
}
.title {
 // font-family: PingFang SC;
  font-weight: normal;
  font-size: 20px;
  color: #000000;
  padding-left: 10px;
  padding-right: 15px;
  line-height: 2;
  //padding-top: 18px;

/*  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; !* 使用旧版的Flexbox *!
  -webkit-line-clamp: 1; !* 限制为两行 *!
  -webkit-box-orient: vertical; !* 设置方向为垂直 *!*/
}
.msg {
 // font-family: PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  line-height: 30px;
  opacity: 0.8;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; /* 使用旧版的Flexbox */
  -webkit-line-clamp: 2; /* 限制为两行 */
  -webkit-box-orient: vertical; /* 设置方向为垂直 */
}
.item:hover{
  border-bottom: 2px solid #a12916;
}
</style>
