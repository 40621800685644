<template>
  <div class="trade_unionBg">
    <div class="trade_union w1364">
      <div class="trends_title">
        <p>
          {{ title }}
          <!--        <span>PARTY AND MASS TRADE UNIONS</span>-->
        </p>
        <span class="seeMore" @click="seeMore">查看更多</span>
      </div>
      <div class="trade_union_content">
        <div class="trade_union_left" v-if="datas.length > 0">
          <img
            class="topSw"
            :src="datas[0].thumbnailInfo.thumbnailUrl"
            alt=""
          />
          <div class="time">
            {{ $moment(datas[0].publishTime).format("YYYY-MM-DD") }}
          </div>
          <div
            class="title"
            @click="toDetail(datas[0])"
            style="cursor: pointer"
          >
            {{ getTwoLine(datas[0].title) }}
          </div>
        </div>
        <div class="trade_union_right">
          <div
            class="item"
            v-for="(item, index) in noFirst"
            :key="index"
            @click="toDetail(item)"
          >
            <div class="time1">
              {{ $moment(item.publishTime).format("MM/DD") }}
            </div>
            <div class="time2">
              {{ $moment(item.publishTime).format("YYYY") }}
            </div>
            <div class="title">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "WgTrade",
  props: {
    // datas: {
    //   type: Array,
    //   default: () => {
    //     return [];
    //   },
    // },
    columnIds: {
      type: String,
      default: () => {
        return "";
      },
    },
    title: {
      type: String,
      default: () => {
        return "党群工会";
      },
    },
  },
  data() {
    return {
      datas: [],
    };
  },
  methods: {
    toDetail(item) {
      this.$emit("toDetail", item);
    },
    seeMore() {
      this.$emit("seeMore", { columnId: this.columnIds, title: this.title });
    },
    getTwoLine(str) {
      if (str.length >= 60) {
        return str.slice(0, 60) + "...";
      } else {
        return str;
      }
    },
  },
  watch: {
    columnIds: {
      async handler(val) {
        if (val) {
          const params = {
            pageNumber: 0,
            pageSize: 10,
            columnIds: val,
          };
          const res = await this.API.basic.getHomeRotographList(params);
          console.log("res", res);
          this.datas = res.data.content.slice(0, 5);
        }
      },
      immediate: true,
    },
  },
  computed: {
    noFirst() {
      return this.datas.filter((item, index) => {
        return index > 0;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.trade_unionBg {
  background: url("../assets/img/党群工会680.png") no-repeat;
  background-size: 100% 100%;
  .w1364{
    padding: 30px 0px!important;
  }
}
.trade_union {
  width: 100%;
  padding: 30px 270px;
}
.trends_title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  p {
    font-weight: 500;
    font-size: 28px;
    color: #000000;
  }
  span {
    font-weight: 400;
    font-size: 24px;
    color: #000000;
    opacity: 0.4;
    margin-left: 13px;
  }
}
.trade_union_content {
  display: flex;
  width: auto;
  .trade_union_right {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    //gap: 18px;
    .item {
      width: 48%;
      height: 225px;
      background: #ffffff;
      padding: 22px 42px;
      margin-bottom: 10px;
      cursor: pointer;
      .title {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #333333;
        line-height: 2;
        margin-top: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box; /* 使用旧版的Flexbox */
        -webkit-line-clamp: 2; /* 限制为两行 */
        -webkit-box-orient: vertical; /* 设置方向为垂直 */
      }
      .time1 {
        font-family: Gotham;
        font-weight: 500;
        font-size: 20px;
        color: #333333;
      }
      .time2 {
        font-family: Gotham;
        font-weight: 500;
        font-size: 16px;
        //color: #999999;
      }
    }
  }
  .trade_union_left {
    padding-bottom: 20px;
    flex: 1;
    .topSw {
      width: 624px;
      height: 350px;
    }
    .time {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 20px;
      color: #000000;

      margin-top: 10px;
      width: 624px;
    }
    .title {
      font-weight: 400;
      font-size: 20px;
      color: #000000;
      //margin-top: 10px;
      width: 624px;
      line-height: 2;
    }
  }
}
.seeMore {
  font-weight: 400;
  font-size: 16px !important;
  color: #000000;
  opacity: 0.4;
  cursor: pointer;
}
</style>
