<template>
    <div>
      <!-- <p class="countDays"> {{ days}} 天 </p> -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'Countdown',
    props: {
      targetDate: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        countdown: null, // 定时器
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      };
    },
    methods: {
      startCountdown() {
        const target = new Date(this.targetDate);
        this.countdown = setInterval(() => {
          const now = new Date();
          const diff = target - now;
          console.log(now)
          console.log(target)
          console.log(diff)
          if (diff <= 0) {
            clearInterval(this.countdown);
            return;
          }
  
          const days = Math.floor(diff / (1000 * 60 * 60 * 24));
          const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((diff % (1000 * 60)) / 1000);
          if(days<1){
            this.days = 0;
          }else{
            this.days = days;
          }
          this.$emit('getDays',this.days)
          this.hours = hours;
          this.minutes = minutes;
          this.seconds = seconds;
        }, 1000);
      }
    },
    mounted() {
      this.startCountdown();
    },
    beforeDestroy() {
      clearInterval(this.countdown);
    }
  };
  </script>
  <style scoped>
  @import url('../assets/css/font.css');
.countDays{
    font-family: 'Source Han Serif CN', serif;
}
</style>