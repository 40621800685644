<!--
 * @Author: huajun huajun.ma@wenge.com
 * @Date: 2024-07-16 14:19:15
 * @LastEditors: huajun huajun.ma@wenge.com
 * @LastEditTime: 2024-07-29 11:43:37
 * @FilePath: \econdarywebsitetemplate\src\views\Home.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="homePage  home_Page" v-if="college === 'whycbxy'">
    <!-- 首页轮播图 -->
    <!-- <WgCarousel :columnIds="$columnIdFile['轮播图']" @toDetail="toDetail" /> -->
    <div class="contentBanner">
      <WgNews
        :columnIds="$columnIdFile['综合新闻']"
        title="综合新闻"
        @toDetail="toDetail"
        @seeMore="seeMore"
      />
      <WgNotice
        :columnIds="$columnIdFile['学院公告']"
        title="学院公告"
        @toDetail="toDetail"
        @seeMore="seeMore"
      />
      <WgPerson
        :columnIds="$columnIdFile['科研平台']"
        title="科研平台"
        @toDetail="toDetail"
        @seeMore="seeMore"
      />
      <WgMedia
        :columnIds="$columnIdFile['教育教学']"
        title="教育教学"
        @toDetail="toDetail"
        @seeMore="seeMore"
      />
      <WgList
        :columnIds="$columnIdFile['党建工作']"
        title="党建工作"
        @toDetail="toDetail"
        @seeMore="seeMore"
      />
      <WgScienceTwo
        :columnIds="$columnIdFile['实习实践']"
        title="实习实践"
        @toDetail="toDetail"
        @seeMore="seeMore"
        style="padding-bottom: 6.30208vw;"
        
      />
      <WgSwiper 
          v-if="false"
          :columnIds="$columnIdFile['综合新闻']"
          title="高翻相册"
          @toDetail="toDetail"
          @seeMore="seeMore"  />

<!--      // test-->

      <!--   通知公告   -->
      <WgNoticeTwo
          v-if="false"
          :columnIds="$columnIdFile['综合新闻']"
          @toDetail="toDetail"
          @seeMore="seeMore"
      />
      <!--   中间大两边小的新闻    -->
      <WgNewsTwo
          v-if="false"
          :columnIds="$columnIdFile['综合新闻']"
          title="综合新闻"
          @toDetail="toDetail"
          @seeMore="seeMore"
      />
      <!--   科学研究   -->
      <WgScience
          v-if="false"
          :columnIds="$columnIdFile['综合新闻']"
          @toDetail="toDetail"
          @seeMore="seeMore"
      />
      <!--  党群工会    -->
      <WgTrade
          v-if="false"
          :columnIds="$columnIdFile['综合新闻']"
          @toDetail="toDetail"
          @seeMore="seeMore"
          style="height: 34.375vw;"
      />
<!--      <WgMediaReport-->
<!--          :columnIds="$columnIdFile['综合新闻']"-->
<!--          @toDetail="toDetail"-->
<!--          @seeMore="seeMore"-->
<!--      />-->



    </div>
  </div>
  <div class="homePage  " v-else-if="college === 'd9cdydbdh'">
   
    <component
          v-for="(data) in homeArr"
          :key="data.is"
          :is="data.is"
          :maxLength="data.maxLength"
          @toDetail="toDetail"
          @seeMore="seeMore"
          :columnIds="data.id"
          :title="data.title"> </component>
    
  </div>
  <div v-else class="homePage" :class="{'home_Page':!Carousel}">
    <WgCarousel v-if="Carousel" :columnIds="$columnIdFile['轮播图']" @toDetail="toDetail" />
    <div class="contentBanner">
     
      <component
          v-for="(data) in homeArr"
          :key="data.is"
          :is="data.is"
          :maxLength="data.maxLength"
          @toDetail="toDetail"
          @seeMore="seeMore"
          :columnIds="data.id"
          :title="data.title"> </component>
    </div>
  </div>
</template>

<script>
import WgCarousel from "../components/WgCarousel.vue";
import WgNews from "../components/WgNews.vue";
import WgNewsTwo from "../components/WgNewsTwo.vue";
import WgNotice from "../components/WgNotice.vue";
import WgNoticeTwo from "../components/WgNoticeTwo.vue";
import WgScience from "../components/WgScience.vue";
import WgScienceTwo from "../components/WgScienceTwo.vue";
import WgMedia from "../components/WgMedia.vue";
import WgList from "../components/WgList.vue";
import WgPerson from "../components/WgPerson.vue";
import WgTrade from "../components/WgTrade.vue";
import WgHistory from "../components/WgHistory.vue";
import WgMediaDoubleRow from "../components/WgMediaDoubleRow.vue";
import WgSwiper from "../components/WgSwiper.vue";
import WgFadeSwiper from "../components/WgFadeSwiper.vue";
import WgMediaThreeRow from "../components/WgMediaThreeRow.vue";
import WgSxyScienceTow from "../components/WgSxyScienceTow.vue";

import {allCollegeInfo} from "../allCollegeInfo";
// import WgMediaReport from "../components/WgMediaReport.vue";
// import WgTextDetail from "../components/WgTextDetail.vue";
export default {
  name: "Home",
  components: {
    WgCarousel,
    //WgNews,
    WgNotice,
    WgScience,
    WgMedia,
    WgNews,
    WgNoticeTwo,
    WgScienceTwo,
    WgList,
    WgPerson,
    WgTrade,
    WgNewsTwo,
    WgHistory,
    // WgMediaReport
    // WgTextDetail
    WgMediaDoubleRow,
    WgSwiper,
    WgFadeSwiper,
    WgMediaThreeRow,
    WgSxyScienceTow
  },
  data() {
    return {
      college: this.$store.state.college,
      Carousel:true,
      homeArr: [],
      component: {
        WgCarousel,
        WgNotice,
        WgScience,
        WgMedia,
        WgNews,
        WgNoticeTwo,
        WgScienceTwo,
        WgList,
        WgPerson,
        WgTrade,
        WgNewsTwo,
        WgMediaDoubleRow,
      }
    };
  },
  mounted() {
    if (!allCollegeInfo(this)[this.college].header?.Carousel) {
      this.Carousel = allCollegeInfo(this)[this.college].header.Carousel;
    }
    this.homeArr = this.college === 'whycbxy' ? []:allCollegeInfo(this)[this.college].content.homeArr;
    
  },
  methods: {
    seeMore(item) {
      this.$router.push({
        name: "newsTrends",
        query: {
          columnId: item.columnId,
          title: item.title,
          tab:'1'
        },
      });
    },
    toDetail(item) {
      if (item.mapProperties) {
        if (item.mapProperties.erOriginLink) {
          window.open(item.mapProperties.erOriginLink);
        } else {
          const { href } = this.$router.resolve({
            name: "newsDetail",
            params: {
              columnId: item.columnId,
              id: item.id,
            },
          });
          window.open(href, '_blank')
        }
      } else {
        const { href } = this.$router.resolve({
          name: "newsDetail",
          params: {
            columnId: item.columnId,
            id: item.id,
          },
        });
        window.open(href, '_blank')
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.homePage {
  // padding-top: 190px;
  position: relative;
  // top: -141px;
  margin-bottom: 20px;
  .contentBanner {
    // display: flex;
    // justify-content: center;
    background: #fff;
    .city {
      background: #fff;

      width: 100%;
    }
    /* ----------------------------------- */

    .city {
      width: 100%;
    }
  }
}
.home_Page{
  top: 141px!important;
}
</style>
