<template>
    <div class="swiper-box">
        <div class="swiper-content ">
            <div class="swiper">
                <swiper :options="swiperOptions" v-if="slides.length">
                    <swiper-slide v-for="(slide, index) in slides" :key="index" >
                        <img  :src="slide.thumbnailInfo.thumbnailUrl" alt="">
                        <!-- <img  :src="slide.src" alt=""> -->

                        <!-- <p>{{ slide.title }}</p> -->
                    </swiper-slide>
                </swiper>
                <!-- 翻页按钮 -->
                <!-- <div class="swiper-button-prev" ></div>
                <div class="swiper-button-next" ></div> -->
                <!-- 分页器 -->
                 <div class="swiper-pagination"></div>
            </div>
        </div>
      
        
    </div>
	

</template>
<script>
 export default {
    name:"WgSwiper",
    props: {
    columnIds: {
      type: String,
      default: () => {
        return "";
      },
    },
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
    data() {
        return {
            slides:[
                // {
                //     src:require('../assets/img/bg1.png')
                // },
                // {
                //     src:require('../assets/img/bg2.png')
                // },
                // {
                //     src:require('../assets/img/bg3.png')
                // }
            ],
            swiperOptions: {
                effect: 'fade',
                loop:true,
                autoplay: {
                  delay: 3000, // 自动播放间隔时间，单位是毫秒
                  disableOnInteraction: false, // 用户操作后是否停止自动播放
                },
                grabCursor: true,
                centeredSlides: true,
                slidesPerView: 'auto',
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true, // 使分页器可点击
                    bulletClass: 'custom-bullet',
                    bulletActiveClass: 'custom-bullet-active',
                },
             
            },
            active: 0,

        }
    },

    methods: {
        toDetail(item) {
          console.log(item)
        this.$emit("toDetail", item);
        },
        seeMore() {
        this.$emit("seeMore", { columnId: this.columnIds, title: this.title });
        },
    },
    watch: {
    columnIds: {
      async handler(val) {
        if (val) {
          this.active = 0;
          const params = {
            pageNumber: 0,
            pageSize: 5,
            columnIds: val,
          };
          const res = await this.API.basic.getHomeRotographList(params);
          console.log(res.data.totalElements)
          if(res.data.totalElements.length != 0){
            this.slides = res.data.content.filter(item=>{
            if(item.thumbnailInfo.thumbnailUrl != ''){
                return item;
            }
          });
          }else{
            this.slides = []
          }
         
        }
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped >
.swiper-box{
    background: url("../assets/img/教育教学369.png") no-repeat;
    background-size: 100% 100%;
   
}
.swiper {
 width: 100%;
 height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.swiper-container {
/**   width: 1070px;
     height: 350px; */
  width: 100%;
  height: 100%;
}
::v-deep .swiper-slide {
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
::v-deep .swiper-slide p{
    position: absolute;
    width: 100%;
    color: #fff;
    box-sizing: border-box;
    padding: 10px;
    bottom: 0;
    background-color: #0000008e;
}
::v-deep .swiper-slide img{
    width: 100%;
    height: 100%;
}
.scientific_title {
  height: 80px;
  line-height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.scientific_title p {
  width: 400px;
  font-weight: 500;
  font-size: 28px;
  color: #000000;
  /* margin-left: 100px; */
}
.scientific_title p span {
  font-size: 24px;
  color: #999;
  margin-left: 10px;
  font-weight: 400;
}
.seeMore {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  opacity: 0.4;
  cursor: pointer;
}
.swiper-button-prev{
    background-image: url("../assets/img/arrow-left.png");
    left: 80px;
}
.swiper-button-next{
    background-image: url("../assets/img/arrow-right.png");
    right: 80px;
}
::v-deep .swiper-pagination{
    bottom: 10px;
    left: 0;
    right: 0;
}
::v-deep .custom-bullet{
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 100%;
    background: transparent;
    border: 1px solid #fff;
    margin: 0 5px;
    cursor: pointer;
}
::v-deep .custom-bullet-active{
    background: #fff;
}

</style>