<template>
    <div class="scientificBg">
      <div class="scientific w1364">
        <div class="scientific_title">
          <p>{{ title }}
  <!--          <span>NEWS</span>-->
          </p>
          <span class="seeMore" @click="seeMore">查看更多</span>
        </div>
        <div class="scientific_left">
          <div
            :class="active === index ? 'active' : 'normal'"
            v-for="(item, index) in datas"
            :key="index"
            @click="toMore(item,index)"
            @mouseover="active = index"
          >
            <div class="ellipsis2">{{ item.title }}</div>
          </div>
        </div>
        <div class="scientific_right">
          <img :src="datas[active]?.thumbnailInfo?.thumbnailUrl" alt="" />
          <div class="msg">
            <div class="left"></div>
            <div class="right">
              <div class="title">
                <div class="ellipsis1">{{ datas[active]?.title }}</div>
              </div>
              <div class="btn" @click="toDetail(datas[active])">详情</div>
  <!--            <div class="info">-->
  <!--              <div class="ellipsis3">-->
  <!--                {{ datas[active]?.description }}-->
  <!--              </div>-->
  <!--            </div>-->
            </div>
          </div>
          <!-- <div>
            <p>{{ datas[active].title }} <span>详情</span></p>
            <p>
              旅游管理学院的奔了教堂在量具于依靠地位，具有以下几方面的特点：一：办学经验丰富，早在80年代初就开办旅游专业，为国家首批开办本科旅游专业的院校；二：办学特色明显，毕业生具备了扎实的旅游专业基础知识和较强的外语能力，适应我国改革开放对人才的需求旅游管理学院的奔了教堂在量具于依靠地位，具有以下几方面的特点：一：办学经验丰富，早在80年代初就开办旅游专业，为国家首批开办本科旅游专业的院校；二：办学特色明显，毕业生具备了扎实的旅游专业基础知识和较强的外语能力，适应我国改革开放对人才的需求...
            </p>
          </div> -->
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "WgScienceTwo",
    props: {
      columnIds: {
        type: String,
        default: () => {
          return "";
        },
      },
      title: {
        type: String,
        default: () => {
          return "";
        },
      },
    },
    data() {
      return {
        datas: [],
        active: 0,
      };
    },
    methods: {
      toDetail(item) {
        this.$emit("toDetail", item);
      },
      seeMore() {
        this.$emit("seeMore", { columnId: this.columnIds, title: this.title });
      },
      toMore(item,index){
        this.active = index;
        this.$router.push({
        name: "newsTrends",
        query: {
          columnId: item.columnId,
          title: item.title,
          tab:'2'
        },
      });
       
      }
    },
    watch: {
      columnIds: {
        async handler(val) {
          if (val) {
            // this.active = 0;
            // const params = {
            //   pageNumber: 0,
            //   pageSize: 10,
            //   columnIds: val,
            // };
            // const res = await this.API.basic.getHomeRotographList(params);
  
            // this.datas = res.data.content.slice(0, 4);
            this.datas = [
                {
                    title:'本科教学',
                    columnId:'66b0975fcf1e66a18c75b27a',
                    thumbnailInfo:{
                        thumbnailUrl:''
                    }
                },
                {
                    title:'学术硕士',
                    columnId:'66b09763cf1e66a18c75b27b',
                    thumbnailInfo:{
                        thumbnailUrl:''
                    }
                },
                {
                    title:'MPAcc',
                    columnId:'66b09768cf1e66a18c75b27c',
                    thumbnailInfo:{
                        thumbnailUrl:''
                    }
                },
                {
                    title:'MBA',
                    columnId:'66b0976ccf1e66a18c75b27d',
                    thumbnailInfo:{
                        thumbnailUrl:''
                    }
                }
            ]
            // console.log('res.data.content', res.data.content, this.datas)
            // this.datas = [...this.datas, ...this.datas];
          }
        },
        immediate: true,
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .ellipsis3 {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .scientific {
    width: 1380px;
    margin: 10px 268px 0 271px;
    height: 600px;
  }
  .scientificBg {
    background: #fefefe;
  }
  .scientific_title {
    height: 80px;
    line-height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .scientific_title p {
    width: 80%;
    font-weight: 500;
    font-size: 28px;
    color: #000000;
    /* margin-left: 100px; */
  }
  .scientific_title p span {
    font-size: 24px;
    color: #999;
    margin-left: 10px;
    font-weight: 400;
  }
  .scientific_left {
    margin-right: 24px;
    width: 450px;
    float: left;
  
    .active {
      width: 432px;
      height: 122px;
      background: #a12916;
      box-shadow: 0px 0px 38px 0px rgba(210, 201, 199, 0.3);
      font-weight: 400;
      font-size: 20px;
      color: #ffffff;
      padding: 18px 30px;
      cursor: pointer;
    }
    .normal {
      width: 432px;
      height: 122px;
      background: #ffffff;
      box-shadow: 0px 0px 38px 0px rgba(210, 201, 199, 0.3);
      font-weight: 400;
      font-size: 20px;
      color: #000000;
      padding: 18px 30px;
      cursor: pointer;
    }
  }
  .scientific_right {
    float: right;
    height: 500px;
    overflow-y: hidden;
    width: calc(100% - 474px);
    color: white;
    position: relative;
    img {
      width: 100%;
      // height: 100%;
    }
    .msg {
      position: absolute;
      top: 412px;
      left: 47px;
      display: flex;
      .left {
        width: 1px;
        height: 30px;
        background: #f6f6f6;
      }
      .right {
        display: flex;
        margin-left: 32px;
        flex-wrap: wrap;
        .info {
          max-width: 600px;
          font-weight: 400;
          font-size: 16px;
          color: #ffffff;
          line-height: 30px;
        }
        .title {
          font-weight: 500;
          font-size: 24px;
          color: #ffffff;
          max-width: 500px;
          .ellipsis1 {
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
        .btn {
          width: 100px;
          height: 40px;
          background: #ffffff;
          border-radius: 25px;
          // font-family: PingFang SC;
          font-weight: 400;
          font-size: 20px;
          color: #a12916;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin-left: 10px;
          position: relative;
          top: -4px;
        }
      }
    }
  }
  .seeMore {
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    opacity: 0.4;
    cursor: pointer;
  }
  </style>
  