<template>
  <div class="xwxq">
    <div class="w1380 " id="by">
      <div style="font-size: 1.45833vw;font-weight: bold;margin-bottom: 1.04167vw;">
        {{title}}
      </div>
      <div id="focusImage" class="slide">
        <ul class="contents">
          <li
              v-for="(item, index) in datas.slice(0, 5)"
              :key="index"
              :class="index == currentIdx ? 'current' : ''"
          >
            <template v-if="item.mapProperties.erOriginLink">
              <a :href="item.mapProperties.erOriginLink" target="_blank">
                <div class="image" v-if="item.thumbnailInfo">
                  <a href="#"
                  ><img
                      :src="item.thumbnailInfo.thumbnailUrl"
                      width="770"
                      height="460"
                  /></a>
                </div>
                <div class="text">
                        <span class="title ellipsis2" style="height: 4.6875vw">
                          <a>{{ item.title }}</a>
                        </span>
                  <p class="ellipsis2" style="height: 2.76042vw">
                    {{ item.description }}
                  </p>
                  <span class="xq"
                  ><a>{{ $t("viewDetails") }}></a></span
                  >
                </div>
              </a>
            </template>
            <template v-else>
              <div @click="toNewsDetail(item)">
                <div class="image" v-if="item.thumbnailInfo">
                  <a href="#"
                  ><img
                      :src="item.thumbnailInfo.thumbnailUrl"
                      width="770"
                      height="460"
                  /></a>
                </div>
                <div class="text">
                        <span class="title ellipsis2" style="height: 4.6875vw">
                          <a>{{ item.title }}</a>
                        </span>
                  <p class="ellipsis2" style="height: 2.76042vw">
                    {{ item.description }}
                  </p>
                  <span class="xq"
                  ><a>查看详情></a></span
                  >
                </div>
              </div>
            </template>
          </li>
        </ul>
        <div class="icon-dot">
          <div
              class="icon-dot-item"
              v-for="(item, index) in datas.slice(0, 5)"
              :key="index"
              @mouseover="wrapLb(index)"
              @mouseleave="onplayLb"
              :class="index == currentIdx ? 'current' : ''"
          >
            <template v-if="item.mapProperties.erOriginLink">
              <a :href="item.mapProperties.erOriginLink" target="_blank">
                      <span v-if="item.columnIsTop" class="column_dot_is_top">{{
                          $t("top")
                        }}</span>
                <template v-if="item.thumbnailInfo">
                  <img :src="item.thumbnailInfo.thumbnailUrl" />
                </template>
                <div>
                  <p class="xykd_type ellipsis4" style="height: 5.20833vw">
                    {{ item.title }}
                  </p>
                </div>
              </a>
            </template>
            <template v-else>
              <div @click="toNewsDetail(item)" style="padding: 0">
                <span v-if="item.columnIsTop" class="column_dot_is_top">top</span>
                <template v-if="item.thumbnailInfo">
                  <img :src="item.thumbnailInfo.thumbnailUrl" />
                </template>
                <div>
                  <p class="xykd_type ellipsis4" style="height: 5.20833vw">
                    {{ item.title }}
                  </p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WgHistory",
  props: {
    columnIds: {
      type: String,
      default: () => {
        return "";
      },
    },
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
    maxLength: {
      type: Number,
      default: () => {
        return 4;
      },
    },
  },
  data() {
    return {
      datas: [],
      currentIdx: 0,
      timer: null,
    }
  },
  watch: {
    columnIds: {
      async handler(val) {
        if (val) {
          const params = {
            pageNumber: 0,
            pageSize: 10,
            columnIds: val,
          };
          const res = await this.API.basic.getHomeRotographList(params);
          this.datas = res.data.content//.slice(0, this.maxLength);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.onplayLb();
  },
  methods: {
    onplayLb() {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        if (this.currentIdx <= 3) {
          this.currentIdx++;
        } else {
          this.currentIdx = 0;
        }
      }, 3000);
    },
    wrapLb(idx) {
      clearInterval(this.timer);
      this.currentIdx = idx;
    },
    toNewsDetail(item) {
      this.$emit("toDetail", item);
    }
  }
}
</script>

<style scoped lang="scss">
.xykd {
  padding-top: 65px;
  height: 875px;
  position: relative;
  .title {
    display: flex;
    justify-content: center;
  }
}
::v-deep .el-carousel__button {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 50%;
}
::v-deep .el-carousel__indicator.is-active button {
  background-color: #d8d8d8;
}
.xykd .xykd-div1 {
  font-size: 70px;
  color: #d8d8d8;
  text-align: center;
  height: 150px;
  font-family: Source Han Sans CN;
  font-weight: 500;
}
.xykd .xykd-div2-1 {
  height: 40px;
  text-align: center;
  font-size: 28px;
  color: #333333;
  line-height: 40px;
}
.xwxq {
  margin-top: 40px;
  height: 650px;
  overflow-y: hidden;
}
.slide,
.slide li {
  width: 1380px;
}
.slide li {
  overflow: hidden;
  display: none;
}
.slide .current {
  display: block;
}
.slide li .image {
  float: right;
  width: 690px;
  height: 460px;
}
.slide li .text {
  padding: 30px;
  float: left;
  background: url("../assets/img/lbl.png") no-repeat;
  background-size: 100% 100%;
  height: 460px;
  width: 690px;
  padding: 95px 45px;
}
.slide li .text .title {
  font-size: 24px;
  width: 570px;
  display: block;
  color: #3d3d3d;
  font-weight: bold;
  line-height: 150%;
}
.slide li .text .title a {
  font-size: 24px;
  color: #fff;
  line-height: 200%;
  text-indent: 0;
}
.slide li .text p {
  color: #fff;
  font-size: 16px;
  text-indent: 0;
  clear: both;
  line-height: 26px;
  margin-top: 20px;
}
.slide li .text .xq {
  font-size: 14px;
  color: #a12916;
  margin-top: 30px;
  display: block;
  background: #fff;
  width: 145px;
  height: 48px;
  line-height: 48px;
  text-align: center;
}
.slide li .text .xq a {
  color: #a12916;
}
.slide .icon-dot {
  width: 100%;
  margin-top: -80px;
}
.slide .icon-dot .icon-dot-item {
  float: left;
  width: 242px;
  height: 168px;
  display: inline-block;
  position: relative;
  margin: 0 17px;
  cursor: pointer;
}
.slide .icon-dot img {
  width: 242px;
  height: 168px;
}
.slide .icon-dot .icon-dot-item div {
  width: 242px;
  height: 168px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  background: #a2655c;
  opacity: 0.8;
  font-size: 22px;
  color: #fff;
  padding: 40px 20px 0;
}
.slide .icon-dot .icon-dot-item .xykd_type {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 25px;
}
.slide .icon-dot .icon-dot-item .xykd_zy {
  font-size: 14px;
  font-family: Source Han Sans CN;
  line-height: 26px;
  margin-top: 10px;
}
.slide .icon-dot .icon-dot-item.current {
  transition: all 0.6s;
  transform: translateY(-20px);
}
.slide .icon-dot .icon-dot-item p {
  font-size: 16px;
  color: #fff;
  width: 100%;
  line-height: 180%;
}
.ewqh {
  //background: url("../assets/img/ewqhbg.png") no-repeat center center;
  height: 774px;
  padding-top: 63px;
  .ewqh-tit {
    width: 100%;
    height: 37px;
    font-size: 38px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 28px;
    text-align: center;
  }
  .playIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    margin-top: 37px;
    img {
      width: 70px;
      height: 70px;
      cursor: pointer;
    }
  }
  .ewqh-tit1 {
    height: 50px;
    font-size: 50px;
    font-family: Source Han Serif CN;
    font-weight: 500;
    color: #333333;
    text-align: center;
    margin-top: 23px;
  }
  .ewqh-tit2 {
    height: 19px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 28px;
    text-align: center;
    margin-top: 25px;
  }
  .ewqh-div1 {
    height: 450px;
    background-color: #fff;
    box-shadow: 0px 0px 10px #e2dede;
    margin-top: 120px;
  }
  .ewqh-div1 .qh-div2-1 {
    text-align: center;
    font-size: 28px;
    color: #333333;
    padding-top: 45px;
  }
  .ewqh-div1 .qh-div2-2 {
    margin-top: 50px;
  }
  .ewqh-div1 .qh-div2-2 li {
    width: 33.33%;
    float: left;
    text-align: center;
    padding: 0 80px;
  }
  .ewqh-div1 .qh-div2-2 .qh-div2-2-1 {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }
  .ewqh-div1 .qh-div2-2 .qh-div2-2-2 {
    color: #333;
    font-size: 26px;
    font-weight: 500;
  }
  .ewqh-div1 .qh-div2-2 .qh-div2-2-3 {
    font-size: 22px;
    font-weight: 500;
    color: #333333;
  }
  .ewqh-div1 .qh-div2-2 .qh-div2-2-4 {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #888888;
    margin-top: 24px;
  }
  .ewqh-div1 .qh-div2-2 .qh-div2-2-5 {
    font-size: 16px;
    color: #888888;
    margin-top: 50px;
  }
  .ewqh-div1 .qh-div2-2 li:hover .qh-div2-2-5 {
    color: #a12916;
  }
}
.zt {
  //background: url("../assets/img/ztbg.png") no-repeat;
  background-position-y: bottom;
  height: 1016px;
}
.zt ul {
  padding-top: 375px;
  margin-right: -20px;
}
.zt ul li {
  width: 330px;
  height: 540px;
  float: left;
  margin-right: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.6s;
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}
.zt ul li .zt-div {
  position: relative;
  width: 330px;
  height: 540px;
  background-size: 100% 100%;
  padding-top: 172px;
  .zt-div-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .zt-div-tit {
    height: 23px;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
    line-height: 25px;
    text-align: center;
    margin-top: 10px;
  }
  .zt-div-line {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 13px;
    > div {
      width: 18px;
      height: 2px;
      background: #ffffff;
    }
  }
  .zt-div-ctx {
    width: 265px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    line-height: 21px;
    margin: 27px auto 0;
  }
  .zt-div-detail {
    position: absolute;
    right: 0;
    bottom: 30px;
    width: 84px;
    height: 22px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    border-bottom: 1px solid #fff;
  }
}
.zt ul li:nth-of-type(2) {
  margin-top: -75px;
}
.zt ul li:nth-of-type(4) {
  margin-top: -75px;
}

.zt ul li:hover {
  transform: scale(1.1);
}
.sptk {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100000;
  overflow: hidden;
  display: none;

  .video {
    width: 800px;
    height: 600px;
    position: fixed;
    top: 45%;
    left: 50%;
    margin-top: -200px;
    margin-left: -400px;
  }

  .closeVideo {
    position: absolute;
    top: 30%;
    right: -25px;
    cursor: pointer;

    img {
      width: 50px;
      height: 50px;
    }
  }
}


.w1380 {
  width: 1380px;
  margin: 0 auto;
}
</style>
