<template>
  <div class="science">
    <div class="science_title">
      <p>科学研究<span>SCIENTIFIC RESEARCH</span></p>
      <span class="seeMore">查看更多</span>
    </div>
    <div class="science_piece">
      <img src="../assets/img/c1.png" alt="" />
      <p>国<br />家<br />级</p>
      <p>NATIONAL LEVEL</p>
    </div>
    <div class="science_piece">
      <img src="../assets/img/c2.png" alt="" />
      <p>省<br />部<br />级</p>
      <p>PROVINCIAL</p>
    </div>
    <div class="science_piece">
      <img src="../assets/img/c3.png" alt="" />
      <p>地<br />厅<br />级</p>
      <p>BASEMENT LEVEL</p>
    </div>
    <div class="science_piece">
      <img src="../assets/img/c4.png" alt="" />
      <p>重<br />点<br />专<br />项<br />研<br />究<br />任<br />务</p>
      <p>KEY SPECIAL RESEARCH TASKS</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "WgScience",
  props: {
    // datas: {
    //   type: Array,
    //   default: () => {
    //     return [];
    //   },
    // },
    columnIds: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  watch: {
    columnIds: {
      async handler(val) {
        if (val) {
          const params = {
            pageNumber: 0,
            pageSize: 10,
            columnIds: val,
          };
          const res = await this.API.basic.getHomeRotographList(params);

          this.datas = res.data.content.slice(0, 3);

        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      datas: [],
    };
  },
  methods: {
    toDetail(item) {
      this.$emit("toDetail", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.science {
  width: 100%;
  height: 918px;
  background: #eeeeed;
  // padding-top: 20px;
  position: relative;
  color: #fff;
  display: flex;
}
.science_title {
  z-index: 11;
  padding: 0 270px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  top: 88px;
  .seeMore {
    color: #ffffff;
    opacity: 0.4;
    font-size: 16px;
  }
}
.science_title p {
  font-weight: 500;
  font-size: 48px;
  color: #ffffff;
  display: flex;

  align-items: center;
}
.science_title p span {
  font-weight: 400;
  font-size: 24px;
  color: #ffffff;
  opacity: 0.4;
  margin-left: 12px;
  margin-top: 10px;
}

.science_piece {
  flex: 1;
  height: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
}
.science_piece p:nth-child(2) {
  line-height: 53px;
  position: absolute;
  z-index: 1;
  top: 388px;
  left: 225px;
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 50px;
  color: #ffffff;
}
.science_piece p:last-child {
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  width: max-content;
  opacity: 0.6;
  transform: rotate(90deg);
  transform-origin: 0;
  position: absolute;
  top: 380px;
  left: 289px;
}
.seeMore {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  opacity: 0.4;
}
</style>
