<template>
  <div class="noticeBg">
    <div class="notice w1364">
      <div class="notice_title">
        <p>
          {{ title }}
          <!--        <span>ANNOUNCEMENT</span>-->
        </p>
        <span class="seeMore" @click="seeMore">查看更多</span>
      </div>
      <div class="notice_module">
        <div class="noticeItem" v-for="(item, index) in datas" :key="index" @click="toDetail(item)">
          <div class="noticeItemDate">
            {{ $moment(item.publishTime).format("MM/DD") }}
          </div>
          <div class="times">
            {{ $moment(item.publishTime).format("YYYY") }}
          </div>
          <div class="noticeContent ellipsis3">
            {{ getThreeLine(item.title) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "WgNotice",
  props: {
    // datas: {
    //   type: Array,
    //   default: () => {
    //     return [];
    //   },
    // },
    columnIds: {
      type: String,
      default: () => {
        return "";
      },
    },
    title: {
      type: String,
      default: () => {
        return "通知公告";
      },
    },
  },
  data() {
    return {
      datas: [],
    };
  },
  watch: {
    columnIds: {
      async handler(val) {
        if (val) {
          const params = {
            pageNumber: 0,
            pageSize: 10,
            columnIds: val,
          };
          const res = await this.API.basic.getHomeRotographList(params);
          this.showIndex = 0;
          this.datas = res.data.content.slice(0, 4);
        }
      },
      immediate: true,
    },
  },
  methods: {
    toDetail(item) {
      this.$emit("toDetail", item);
    },
    seeMore() {
      this.$emit("seeMore", { columnId: this.columnIds, title: "通知公告" });
    },
    getThreeLine(str) {
      if (str.length >= 42) {
        return str.slice(0, 42) + "...";
      } else {
        return str;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.noticeBg {
  background: url("../assets/img/WgNoticeTwo.jpg") no-repeat;
  background-size: 100% 100%;
  .w1364{
    padding: 30px 0px!important;
  }
}
.notice {
  padding: 30px 270px 30px 270px;
  width: 100%;
  overflow: hidden;
}
.notice_title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.notice_title p {
  font-weight: 500;
  font-size: 28px;
  display: flex;
  align-items: center;
  color: #000000;
}
.notice_title p span {
  font-weight: 400;
  font-size: 24px;
  color: #000000;
  margin-left: 12px;
  opacity: 0.4;
}
.notice_module {
  display: flex;
  justify-content: space-between;
  .noticeItem {
    cursor: pointer;
    width: 322px;
    height: 220px;
    background: #ffffff;
    padding: 0 28px;
    color: #000000;
    &.active {
      color: #fff;
      background: rgba(192, 53, 48, 1);
    }
    .noticeItemDate {
      margin-top: 20px;
      font-family: Gotham;
      font-weight: 600;
      font-size: 20px;
    }
    .times {
      font-family: Gotham;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 20px;
    }
    .noticeContent {
      font-weight: 400;
      font-size: 18px;
      line-height: 2;
    }
  }
}
.notice_module dl {
  width: 24%;
  height: 250px;
  background: #fff;
}
.notice_module dl dt {
  margin: 50px 0 0 40px;
}
.notice_module dl dt p:first-child {
  font-size: 30px;
}
.notice_module dl dt p:last-child {
  font-size: 18px;
}
.notice_module dl dd {
  font-size: 18px;
  margin: 50px 40px 0 40px;
}
.seeMore {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  opacity: 0.4;
  cursor: pointer;
}
</style>
