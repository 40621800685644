<template>
  <div class="news_infoBg">
  <div class="news_info w1364">
    <div class="news_info_title">
      <p>{{ title }}
<!--        <span>NEWS</span>-->
      </p>
      <span class="seeMore" @click="seeMore">查看更多</span>
    </div>
    <div class="center">
      <!-- <div class="news_info_slide">
        <template v-if="datas.length > 1">
          <img
            v-if="datas[1].thumbnailInfo.thumbnailUrl"
            :src="datas[1].thumbnailInfo.thumbnailUrl"
          />
          <div
            class="news_info_slide_item"
            style="cursor: pointer"
            v-for="(item, index) in datasOdd"
            :key="index"
            @click="toDetail(item)"
          >
            <div> {{ $moment(item.publishTime).format("YYYY-MM-DD") }}</div>
            <div>{{ getTwoLine(item?.title) }}</div>
          </div>
        </template>
      </div> -->
      <div class="news_info_center" v-for="(item, index) in datas" :key="index">
        <template >
          <div class="news_info_center_img">
            <img :src="item.thumbnailInfo.thumbnailUrl" alt="" />
          </div>
          <div
            class="news_info_center_title"
            style="cursor: pointer;padding-top: 1.04167vw"
          >
            {{ $moment(item.publishTime).format("YYYY-MM-DD") }}
          </div>
          <div
            class="news_info_center_title"
            style="cursor: pointer;margin-bottom: 1.5625vw;"
            @click="toDetail(item)"
          >
            {{ getTwoLine(item?.title) }}
          </div>
<!--          <div-->
<!--            class="news_info_center_msg"-->
<!--            style="cursor: pointer"-->
<!--            @click="toDetail(datas[0])"-->
<!--          >-->
<!--            {{ datas[0]?.description }}-->
<!--          </div>-->
        </template>
      </div>
      <!-- <div class="news_info_slide">
        <template v-if="datas.length > 2">
          <div class="news_info_slide_item_img">
            <img
              v-if="datas[2].thumbnailInfo.thumbnailUrl"
              :src="datas[2].thumbnailInfo.thumbnailUrl"
              alt=""
            />
          </div>
          <div
            class="news_info_slide_item"
            v-for="(item, index) in datasEven"
            style="cursor: pointer"
            :key="index"
            @click="toDetail(item)"
          >
            <div> {{ $moment(item.publishTime).format("YYYY-MM-DD") }}</div>
            <div>{{ getTwoLine(item?.title) }}</div>
          </div>
        </template>
      </div> -->
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: "WgNewsTwo",
  props: {
    columnIds: {
      type: String,
      default: () => {
        return "";
      },
    },
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      datas: [],
    };
  },
  methods: {
    toDetail(item) {
      this.$emit("toDetail", item);
    },
    seeMore(){
      this.$emit('seeMore',{columnId:this.columnIds,title:this.title})
    },
    getTwoLine(str) {
      if (str.length >= 50 ) {
        return str.slice(0, 50) + '...';
      } else {
        return str;
      }
    },
  },
  watch: {
    columnIds: {
      async handler(val) {
        if (val) {
          const params = {
            pageNumber: 0,
            pageSize: 10,
            columnIds: val,
          };
          const res = await this.API.basic.getHomeRotographList(params);

          this.datas = res.data.content.slice(0, 3);

        }
      },
      immediate: true,
    },
  },
  computed: {
    datasOdd() {
      return this.datas.filter((item, index) => {
        return index % 2 !== 0 && index > 0;
      });
    },
    datasEven() {
      return this.datas.filter((item, index) => {
        return index % 2 === 0 && index > 0;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.news_infoBg{
  background: url("../assets/img/新闻2-538.png") no-repeat;
  background-size: 100% 100%;
  .w1364{
    padding: 30px 0px 0px 0px;
  }
}
.news_info {
  padding: 30px 268px 0px 271px;
  width: 100%;
  overflow: hidden;
}
.news_info_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.news_info_title p {
  font-weight: 500;
  font-size: 28px;
  color: #000000;
  /* margin-left: 100px; */
}
.news_info_title p span {
  margin-left: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 24px;
  color: #000000;
  opacity: 0.4;
}
.seeMore {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  opacity: 0.4;
  cursor:pointer;
}
.news_info .center {
  display: flex;
  justify-content: space-between;
  .news_info_center_title {
    font-family: PingFang SC;
    //font-weight: 500;
    font-size: 20px;
    color: #000000;
    line-height: 2;
    //text-align: center;
  }
  .news_info_center_msg {
    font-weight: 400;
    font-size: 18px;
    color: #999999;
  }
  .news_info_slide {
    width: 400px;
    .news_info_slide_item_img {
      height: 225px;
      overflow-y: hidden;
    }
    //margin-top: 13px;
    img {
      width: 100%;
      //height: 215px;
      //margin-bottom: 30px;
    }
    .news_info_slide_item {
      padding-top: 20px;
      padding-bottom: 20px;
      line-height: 2;
      //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 49px;
      font-weight: 400;
      font-size: 20px;
      color: #333333;
      &:last-child {
        margin-bottom: 0;
      }

    }
  }
  .news_info_center {
    width: 400px;
    img {
      //width: 545px;
      //height: 306px;
      width: 100%;
      height: 100%;
    }
    .news_info_center_img {
      height: 225px;
      overflow-y: hidden;
    }
  }
}
</style>
